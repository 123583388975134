<template>
  <button
    class="i-button"
    :class="classes"
    :type="type"
  >
   <span class="i-button__content">
    <slot/>
   </span>
  </button>
</template>

<script>
import './i-button.scss';

export default {
  name: 'Button',
    computed: {
      classes() {
          return {
              'i-button_secondary': this.secondary,
              'i-button_back': this.back,
          }
      }
    },
  props: {
      type: {
          type: String,
          default: 'button'
      },
      secondary: {
          type: Boolean,
          default: false
      },
      back: {
          type: Boolean,
          default: false
      },
  }
};
</script>
